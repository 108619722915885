import { Box } from '@chakra-ui/react'
import ReactHtmlParser from 'react-html-parser'

const RichText = ({ html }) => {
  return (
    <Box>
      {ReactHtmlParser(html, {
        transform: (node, index) => {
          if (node.type === 'tag' && node.name === 'em') {
            return (
              <Box as="em" bg="yellow.200" key={index}>
                {node.children[0]?.data}
              </Box>
            )
          }
        },
      })}
    </Box>
  )
}

export default RichText
