import React, { useState } from 'react'
import { HStack, Input, Tooltip, useDisclosure, useToast } from '@chakra-ui/react'
import DoppelDefaultButton from '../shared/doppel_default_button'
import DropdownSelector from '../shared/dropdown_selector'
import { AlertLinkRelationship, AlertLinkType } from '@/generated/enums'
import {
  Order_By,
  useCreateAlertLinkMutation,
  useGetSpoofingReportsLazyQuery,
} from '@/generated/graphql'
import DoppelAlertDialog from '../shared/doppel_alert_dialog'
import { isInternalId } from '@/utils/reports/report_utils'
import { getReportsWhereClauseFromExternalIdSlug } from '@/hooks/queries'

export type AlertLinkAdderProps = {
  alertId: string
  alertName: string
  refetchAlertLinks: () => void
}

export function AlertLinkAdder({
  refetchAlertLinks,
  alertId,
  alertName,
}: AlertLinkAdderProps) {
  const [otherAlertId, setOtherAlertId] = useState('')
  const [alertLinkType, setAlertLinkType] = useState<AlertLinkType | ''>('')
  const [alertLinkRelationship, setAlertLinkRelationship] = useState<
    AlertLinkRelationship | ''
  >('')

  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = React.useRef()
  const height = '35px'

  const [createAlertLinkMutation] = useCreateAlertLinkMutation({
    onCompleted: () => {
      refetchAlertLinks()
      resetFields()
      toast({
        title: 'Successfully created alert link',
        status: 'success',
        isClosable: true,
      })
      onClose()
    },
    onError: (error) => {
      toast({
        title: 'Error creating alert link',
        description: error.message,
        status: 'error',
        isClosable: true,
      })
      onClose()
    },
  })

  const [
    getSpoofingReports,
    { data: externalIdData, loading: loadingExternalId, error: externalIdError },
  ] = useGetSpoofingReportsLazyQuery()

  const createAlertLink = async () => {
    let alert_id_1 = alertId
    let alert_id_2 = otherAlertId

    if (!isInternalId(otherAlertId)) {
      const whereClauseFromExternalId =
        getReportsWhereClauseFromExternalIdSlug(otherAlertId)
      if (whereClauseFromExternalId) {
        try {
          const { data } = await getSpoofingReports({
            variables: {
              spoofingReportsWhere: whereClauseFromExternalId,
              orderBy: [{ created_at: Order_By.Asc }],
            },
          })

          if (data && data.spoofing_reports.length > 0) {
            alert_id_2 = data.spoofing_reports[0].id
          } else {
            toast({
              title: 'Error',
              description: 'No matching alert found for the provided external ID',
              status: 'error',
              isClosable: true,
            })
            return
          }
        } catch (error) {
          toast({
            title: 'Error',
            description: 'Failed to fetch alert details',
            status: 'error',
            isClosable: true,
          })
          return
        }
      }
    }

    let relationship = alertLinkRelationship
    if (alertLinkRelationship === AlertLinkRelationship.PARENT) {
      alert_id_1 = alert_id_2
      alert_id_2 = alertId
      relationship = AlertLinkRelationship.PARENT_CHILD
    } else if (alertLinkRelationship === AlertLinkRelationship.CHILD) {
      // already in correct order
      relationship = AlertLinkRelationship.PARENT_CHILD
    }

    createAlertLinkMutation({
      variables: {
        alert_id_1,
        alert_id_2,
        type: alertLinkType,
        relationship,
      },
    })
  }

  const isCreateLinkDisabled = () => {
    return [otherAlertId, alertLinkType, alertLinkRelationship].some(
      (field) => !field || field === '' || field === null || field === undefined,
    )
  }

  const RELATIONSHIP_OPTIONS = [
    { value: AlertLinkRelationship.PEER_TO_PEER, label: `peer of ${alertName}` },
    { value: AlertLinkRelationship.PARENT, label: `parent of ${alertName}` },
    { value: AlertLinkRelationship.CHILD, label: `child of ${alertName}` },
  ]

  const resetFields = () => {
    setOtherAlertId('')
    setAlertLinkType('')
    setAlertLinkRelationship('')
  }

  return (
    <HStack alignItems="start" marginBottom={4} pb={4} spacing={4}>
      <Tooltip label={'ID of the alert to link'}>
        <Input
          fontSize={12}
          height={height}
          maxWidth={'300'}
          onChange={(e) => setOtherAlertId(e.target.value)}
          placeholder={'Linked Alert ID'}
          type="text"
          value={otherAlertId}
        />
      </Tooltip>

      <Tooltip label="Alert Link Type indicates the nature of the link between alert">
        <span>
          <DropdownSelector
            height={height}
            idField="alert_link_type"
            items={Object.values(AlertLinkType)}
            minWidth={'170px'}
            onItemSelected={(value) => setAlertLinkType(value)}
            placeholder="type"
            selectedItem={alertLinkType}
          />
        </span>
      </Tooltip>

      <Tooltip label="Alert Link Relationship indicates the relationship between the linked reports.">
        <span>
          <DropdownSelector
            height={height}
            idField="value"
            items={RELATIONSHIP_OPTIONS}
            minWidth={'170px'}
            nameField="label"
            onItemSelected={(value) => setAlertLinkRelationship(value)}
            placeholder="relationship"
            selectedItem={alertLinkRelationship}
          />
        </span>
      </Tooltip>

      <DoppelDefaultButton
        h={height}
        isDisabled={isCreateLinkDisabled()}
        isInternal={true}
        onClick={onOpen}
        size="md"
      >
        Create Alert Link
      </DoppelDefaultButton>

      <DoppelDefaultButton h={height} onClick={() => resetFields()} size="md">
        Reset
      </DoppelDefaultButton>

      <DoppelAlertDialog
        body={`Are you sure you want to create a link between these alerts?`}
        cancelRef={cancelRef}
        confirmAction={createAlertLink} // Action to create the alert link
        header="Confirm Create Alert Link"
        isOpen={isOpen}
        onClose={onClose}
      />
    </HStack>
  )
}
