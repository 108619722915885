import {
  Spoofing_Reports_Bool_Exp,
  useGetProductByPlatformQuery,
} from '../generated/graphql'
import { ProductType } from '../utils/constants'

// returns product for a given platform
export function useGetProductByPlatform(platformName: string): ProductType {
  const { data: productData } = useGetProductByPlatformQuery({
    variables: {
      platformName: platformName,
    },
  })
  return productData?.platforms?.[0]?.product
}

function parseExternalIdSlug(slug: string | null) {
  if (!slug) return null
  const parts = slug.split('-')
  if (parts.length !== 2) return null
  return {
    orgAbbrName: parts[0],
    externalId: Number(parts[1]),
  }
}

export function getReportsWhereClauseFromExternalIdSlug(
  externalIdSlug: string | null,
  orgId: string | null = null,
): Spoofing_Reports_Bool_Exp | null {
  const parsedSlug = parseExternalIdSlug(externalIdSlug)
  if (!parsedSlug) return null
  const spoofingReportsWhere: Spoofing_Reports_Bool_Exp = parsedSlug
    ? {
        external_id: { _eq: parsedSlug.externalId },
        organization: { abbr_name: { _eq: parsedSlug.orgAbbrName.toUpperCase() } },
      }
    : {}
  if (orgId) {
    spoofingReportsWhere.organization_id = { _eq: orgId }
  }
  return spoofingReportsWhere
}
