import {
  useToast,
  useDisclosure,
  Input,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  IconButton,
} from '@chakra-ui/react'
import React, { useState, useEffect, useRef } from 'react'
import { DOPPEL_BUTTON_GREY, DOPPEL_WHITE } from '@/utils/style'
import { useGetTagsQuery, useUpdateSpoofingReportsMutation } from 'generated/graphql'
import { useIsEmployeeView } from 'hooks/id_token_claims'
import DoppelAlertDialog from '@/components/shared/doppel_alert_dialog'
import { withApollo } from '@apollo/react-hoc'
import { ReportChangeEventSource, TagActionType } from '@/generated/enums'
import { EditIcon } from '@chakra-ui/icons'
import { getTagDisplayName } from '../../../utils/tags'

function SpoofDetailEditTagsButton({
  reportID,
  orgID,
  currentTags,
  refetchTags,
  setIsTagEdited,
  client,
}) {
  const [updateSpoofingReportsMutation] = useUpdateSpoofingReportsMutation()
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredTags, setFilteredTags] = useState([])
  const [initialTags, setInitialTags] = useState(currentTags || [])
  const [tags, setTags] = useState([])
  const [isEmployeeView] = useIsEmployeeView()

  const { onClose } = useDisclosure()
  const cancelRef = useRef()

  const toast = useToast()

  const [isDialogOpen, setDialogOpen] = useState(false)
  const [isMenuOpen, setMenuOpen] = useState(false)

  const handleMenuClose = () => {
    setMenuOpen(false)
    setDialogOpen(true)
  }

  const handleMenuOpen = () => {
    setMenuOpen(true)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
  }

  const { data: tagsData, loading: tagsLoading } = useGetTagsQuery({
    variables: {
      orgIDs: [orgID],
      includeGlobal: true,
    },
  })

  useEffect(() => {
    if (!tagsLoading && tagsData?.tags) {
      const filtered = tagsData.tags.filter((tag) =>
        tag.name.toLowerCase().includes(searchQuery.toLowerCase()),
      )
      setFilteredTags(filtered)
    }
  }, [tagsData, searchQuery])

  useEffect(() => {
    currentTags && setInitialTags(currentTags.map((tag) => tag.id))
  }, [currentTags])

  useEffect(() => {
    if (initialTags && tagsData?.tags) {
      setTags(initialTags.filter((tag) => tagsData.tags.some((t) => t.id === tag)))
    }
  }, [initialTags, tagsData])

  const handleSearchChange = (event) => setSearchQuery(event.target.value)

  const confirmTags = () => {
    const addedTags = []
    const removedTags = []
    const addTags = tags.filter(
      (tag) => !initialTags.some((initialTag) => initialTag === tag),
    )
    const removeTags = initialTags.filter(
      (initialTag) => !tags.some((tag) => tag === initialTag),
    )

    const addTagPromises = addTags.map((tag) =>
      updateSpoofingReportsMutation({
        variables: {
          input: {
            report_ids: [reportID],
            update_source: ReportChangeEventSource.UI,
            tag_id: tag,
            tag_action: TagActionType.ADD,
          },
        },
      })
        .then(() => {
          addedTags.push(tag)
        })
        .catch((e) => {
          toast({
            title: `Error adding tags`,
            description: `Error: ${e.message}`,
            status: 'error',
            isClosable: true,
          })
        }),
    )

    const removeTagPromises = removeTags.map((tag) =>
      updateSpoofingReportsMutation({
        variables: {
          input: {
            report_ids: [reportID],
            update_source: ReportChangeEventSource.UI,
            tag_id: tag,
            tag_action: TagActionType.REMOVE,
          },
        },
      })
        .then(() => {
          removedTags.push(tag)
        })
        .catch((e) => {
          toast({
            title: `Error removing tags`,
            description: `Error: ${e.message}`,
            status: 'error',
            isClosable: true,
          })
        }),
    )

    Promise.all([...addTagPromises, ...removeTagPromises]).finally(() => {
      const addedTagNames = tagsData.tags
        .filter((tag) => addedTags.includes(tag.id))
        .map((tag) => tag.name)
        .join(', ')
      const removedTagNames = tagsData.tags
        .filter((tag) => removedTags.includes(tag.id))
        .map((tag) => tag.name)
        .join(', ')

      if (addedTagNames) {
        toast({
          title: `Success!`,
          description: `Added tags: ${addedTagNames}`,
          status: 'success',
          isClosable: true,
        })
      }
      if (removedTagNames) {
        toast({
          title: `Success!`,
          description: `Removed tags: ${removedTagNames}`,
          status: 'success',
          isClosable: true,
        })
      }
      if (addedTagNames || removedTagNames) {
        refetchTags()
        setIsTagEdited(true)
      }
      setDialogOpen(false)
      onClose()
    })
  }

  const menuListItems = filteredTags
    ? filteredTags.filter((tag) => isEmployeeView || !tag.is_internal)
    : []

  return (
    <>
      <Menu
        closeOnSelect={false}
        isOpen={isMenuOpen}
        onClose={handleMenuClose}
        onOpen={handleMenuOpen}
      >
        <MenuButton
          aria-label={''}
          as={IconButton}
          bgColor={DOPPEL_BUTTON_GREY}
          color={DOPPEL_WHITE}
          icon={<EditIcon />}
          size="xs"
        ></MenuButton>

        <MenuList maxH="300px" overflowY="auto">
          <MenuOptionGroup
            onChange={(values) => setTags(values as any)}
            type="checkbox"
            value={tags}
          >
            {handleSearchChange && (
              <Input
                m={2}
                onChange={handleSearchChange}
                placeholder="Search tags..."
                variant="filled"
                width="90%"
              />
            )}

            {menuListItems.map((item, index) => (
              <MenuItemOption key={item.key || index} value={item.id}>
                {getTagDisplayName(item)}
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </MenuList>
      </Menu>

      <DoppelAlertDialog
        body={'Confirm editing alert tags?'}
        cancelRef={cancelRef}
        confirmAction={confirmTags}
        header="Edit Alert Tags"
        isOpen={
          isDialogOpen && initialTags.sort().toString() !== tags.sort().toString()
        }
        onClose={handleCloseDialog}
      />
    </>
  )
}

export default withApollo<any>(SpoofDetailEditTagsButton)
