export function getTagDisplayName(tag): string {
  const tagName = [tag.name]
  if (tag.is_internal) {
    tagName.push('[Internal]')
  }
  if (tag.organization_id === null) {
    tagName.push('[Global]')
  }
  return tagName.join(' ')
}
