import { Box } from '@chakra-ui/react'
import { DOPPEL_DARK_SECONDARY } from '@/utils/style'

export default function DoppelCard({ children, bg = DOPPEL_DARK_SECONDARY, ...props }) {
  return (
    <Box {...props} bg={bg} boxShadow={'md'} padding="6" rounded={'sm'}>
      {children}
    </Box>
  )
}
