import { MenuItem, Spinner, Input, useDisclosure, Text } from '@chakra-ui/react'
import React, { useState, useRef, useMemo } from 'react'
import { useGetAllPlatformSubtypesQuery } from '../../generated/graphql'
import { DOPPEL_INTERNAL_PURPLE } from '../../utils/style'
import BulkActionButton from './bulk_action_button'
import DoppelAlertDialog from '../shared/doppel_alert_dialog'
import { PRODUCT_TO_DEFAULT_SUBTYPE } from '@/generated/platform_subtype_mapping'

const makeMenuListItem = (subtype, onClick, showArchetype) => ({
  key: subtype.id,
  content: (
    <MenuItem onClick={onClick}>
      {subtype.name}

      {showArchetype && ` (${subtype.archetype})`}
    </MenuItem>
  ),
})

// janky refactored "hook" only for reusing in modal reroute platform subtype
export const useReroutePlatformSubtypeMenuListItems = (
  handlePlatformSubtypeSelect: (subtype) => void,
) => {
  const [searchQuery, setSearchQuery] = useState('')

  const { data: platformSubtypesData } = useGetAllPlatformSubtypesQuery({
    notifyOnNetworkStatusChange: true,
  })
  const filteredSubtypes = useMemo(() => {
    if (!platformSubtypesData) return []
    return platformSubtypesData.platform_subtypes.filter((subtype) =>
      subtype.name.toLowerCase().includes(searchQuery.toLowerCase()),
    )
  }, [platformSubtypesData, searchQuery])

  // to display at top of list
  // order is currently arbitrary, explicitly define if needed
  const productGenericSubtypeNames: string[] = Object.values(PRODUCT_TO_DEFAULT_SUBTYPE)

  const productGenericSubtypes = useMemo(() => {
    // preserve order that they're defined in mapping
    return productGenericSubtypeNames
      .map((name) => filteredSubtypes.find((subtype) => subtype.name === name))
      .filter((subtype) => !!subtype)
  }, [filteredSubtypes])

  const nonProductGenericSubtypes = useMemo(() => {
    return filteredSubtypes
      .filter((subtype) => !productGenericSubtypeNames.includes(subtype.name))
      .sort((a, b) => {
        const aProduct = a.product.toLowerCase()
        const bProduct = b.product.toLowerCase()
        if (aProduct !== bProduct) {
          return aProduct.localeCompare(bProduct)
        }
        return a.name.localeCompare(b.name)
      })
  }, [platformSubtypesData, searchQuery])

  const handleSearchChange = (event) => setSearchQuery(event.target.value)

  return [
    {
      key: 'searchInput',
      content: (
        <Input
          m={2}
          onChange={handleSearchChange}
          placeholder="Search platforms..."
          variant="filled"
          width="90%"
        />
      ),
    },
    ...productGenericSubtypes.map((subtype) =>
      makeMenuListItem(subtype, () => handlePlatformSubtypeSelect(subtype), false),
    ),
    ...nonProductGenericSubtypes.map((subtype) =>
      makeMenuListItem(subtype, () => handlePlatformSubtypeSelect(subtype), true),
    ),
  ]
}

export default function BulkReroutePlatformSubtypeButton({
  loading,
  selectedRows,
  updateAlertFunc,
}) {
  const [platformSubtypeToReroute, setPlatformSubtypeToReroute] = useState<any>()
  const cancelRef = useRef()

  const handlePlatformSubtypeSelect = (subtype) => {
    setPlatformSubtypeToReroute(subtype)
    onOpen()
  }

  const { isOpen, onOpen, onClose } = useDisclosure()

  const confirmReroute = () => {
    if (platformSubtypeToReroute) {
      updateAlertFunc(selectedRows, {
        platformSubtype: platformSubtypeToReroute,
      })
      onClose()
    }
  }
  const menuListItems = useReroutePlatformSubtypeMenuListItems(
    handlePlatformSubtypeSelect,
  )

  return (
    <>
      <BulkActionButton
        bgColor={DOPPEL_INTERNAL_PURPLE}
        isLoading={loading}
        menuListItems={menuListItems}
      >
        {loading ? <Spinner size="xs" /> : 'Bulk Reroute Platform'}
      </BulkActionButton>

      <DoppelAlertDialog
        body={
          <>
            <Text>
              Are you sure you want to reroute the selected {selectedRows.length}{' '}
              alert(s) to the platform {platformSubtypeToReroute?.name}?
            </Text>

            <Text mt={1}>
              This is a new feature. If an area of the app doesn&apos;t update properly,
              please let us know in #product-support, and try rerouting the platform
              using BOTH this button and the &ldquo;Bulk Reroute Platform (Old)&rdquo;
              button.
            </Text>
          </>
        }
        cancelRef={cancelRef}
        confirmAction={confirmReroute}
        header={`Bulk Reroute Platform: ${platformSubtypeToReroute?.name}`}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  )
}
