import { Flex, Heading, Table, Tbody, Thead } from '@chakra-ui/react'

const TABLE_PREVIEW_HEIGHT = '300px'

export function SpoofDetailModalTable({ header, columns, data }) {
  return (
    <Flex direction="column" paddingBottom={4} width="100%">
      <Flex alignItems="center" marginBottom={2}>
        <Heading as="h4" mr={2} size="md">
          {header}
        </Heading>
      </Flex>

      {data.length > 0 && (
        <>
          <Flex direction="column" maxHeight={TABLE_PREVIEW_HEIGHT} overflowY="auto">
            <Table size="sm" variant="simple">
              <Thead>{columns}</Thead>

              <Tbody>{data}</Tbody>
            </Table>
          </Flex>
        </>
      )}
    </Flex>
  )
}
