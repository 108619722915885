import { Flex, Tag, Text, UnorderedList } from '@chakra-ui/react'
import { useSelectedSpoofReport } from '../../../hooks/spoof_report_table_filters'
import { ProductType } from '../../../utils/constants'
import DoppelLink from '../../report_detail/doppel_link'
import { DoppelJsonView } from '../../shared/doppel_json_view'
import DoppelCard from '../../stats/doppel_card'
import { useIsEmployee } from '../../../hooks/id_token_claims'
import { Ml_Features, useGetMlFeaturesQuery } from '../../../generated/graphql'
import { Table, Tbody, Thead, Tr, Td } from '@chakra-ui/react'
import { DOPPEL_BREACH_RED, DOPPEL_ENCRYPT_GREEN } from '../../../utils/style'

const SpoofDetailDebugView = () => {
  const [selectedSpoofReport] = useSelectedSpoofReport()
  const isEmployee = useIsEmployee()
  if (!selectedSpoofReport || !isEmployee) {
    return <></>
  }

  return (
    <Flex direction="column" padding="20px" width="1200px">
      <DomainDebugView />

      <Text fontSize="xl" fontWeight="bold">
        Json Payload
      </Text>

      <DoppelJsonView src={selectedSpoofReport} />
    </Flex>
  )
}

const DomainDebugView = () => {
  const [selectedSpoofReport] = useSelectedSpoofReport()
  const { data, loading, error } = useGetMlFeaturesQuery({
    variables: {
      match_ids: selectedSpoofReport.spoof_matches.map((match) => match.id),
    },
  })
  if (
    !selectedSpoofReport ||
    selectedSpoofReport.platform.product != ProductType.DOMAIN
  ) {
    return <></>
  }

  const mlFeatureMap = new Map<string, Set<Ml_Features>>()
  if (data) {
    data.ml_features.forEach((ml_feature) => {
      const currentSet = mlFeatureMap.get(ml_feature.match_id) || new Set()
      currentSet.add(ml_feature)
      mlFeatureMap.set(ml_feature.match_id, currentSet)
    })
  }

  return (
    <>
      <Text fontSize="xl" fontWeight="bold">
        Domain Debug Info
      </Text>

      <UnorderedList>
        <Text fontSize="l" fontWeight="bold">
          WHOIS Lookup
        </Text>

        {selectedSpoofReport.first_domain_data[0]?.full_url?.domain_data?.raw_data && (
          <DoppelJsonView
            src={JSON.parse(
              selectedSpoofReport.first_domain_data[0]?.full_url?.domain_data?.raw_data,
            )}
          />
        )}

        <Text fontSize="l" fontWeight="bold">
          ML Features
        </Text>

        {selectedSpoofReport.spoof_matches.map((spoofMatch, index) => (
          <DoppelCard key={index}>
            <Flex alignContent={'center'}>
              <Text fontSize="sm" pr="1">
                {spoofMatch.full_url?.url}:
              </Text>

              <DoppelLink
                href={spoofMatch.full_url?.site_html_url}
                name={
                  spoofMatch.full_url?.site_html_url ? 'HTML Link' : 'No site html url'
                }
              />
            </Flex>

            <Table>
              <Thead>
                <Tr>
                  <Td>Feature Name</Td>

                  <Td>Value</Td>

                  <Td>Extra Data</Td>
                </Tr>
              </Thead>

              <Tbody>
                {Array.from(mlFeatureMap?.get(spoofMatch.id) || [])?.map(
                  (mlFeature: Ml_Features) => (
                    <Tr key={mlFeature.name}>
                      <Td>{mlFeature.name}</Td>

                      <Td>
                        <Tag
                          bgColor={getColorForValue(
                            mlFeature.value,
                            mlFeature.threshold,
                          )}
                          color={'#FFFFFF'}
                          fontSize={12}
                          width="fit-content"
                        >
                          {normalizeFeatureValue(mlFeature.value)}
                        </Tag>
                      </Td>

                      <Td>{JSON.stringify(mlFeature.extra_data, null, 2)}</Td>
                    </Tr>
                  ),
                )}
              </Tbody>
            </Table>
          </DoppelCard>
        ))}
      </UnorderedList>
    </>
  )
}

// Ideally we make all the values floats between 0 and 1, this is a hack to normalize the data
function normalizeFeatureValue(value): number {
  if (value > 1) {
    // may not be accurate
    value = value / 100
  }
  return parseFloat(Math.max(0, Math.min(value, 1)).toPrecision(2))
}

function getColorForValue(value, threshold) {
  if (value < threshold) {
    return DOPPEL_ENCRYPT_GREEN
  }
  return DOPPEL_BREACH_RED
}

export default SpoofDetailDebugView
